export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {},
};
export default result;

export const namedOperations = {
  Query: {
    AdministrationPlacesForPosition: 'AdministrationPlacesForPosition' as const,
    AllDefaultRecommendations: 'AllDefaultRecommendations' as const,
    AllRecommendations: 'AllRecommendations' as const,
    AllVaccineFamiliesByAge: 'AllVaccineFamiliesByAge' as const,
    DoctorRecommendations: 'DoctorRecommendations' as const,
    Doctor: 'Doctor' as const,
    GetDoctorImunocardCount: 'GetDoctorImunocardCount' as const,
    GetFaqs: 'GetFaqs' as const,
    ListCategoriesRecommendation: 'ListCategoriesRecommendation' as const,
    VaccineByFamily: 'VaccineByFamily' as const,
    VaccineBySpecialNeed: 'VaccineBySpecialNeed' as const,
  },
  Mutation: {
    AllUserDataRequest: 'AllUserDataRequest' as const,
    ChangePassword: 'ChangePassword' as const,
    Contact: 'Contact' as const,
    DefaultRecommendationCreate: 'DefaultRecommendationCreate' as const,
    DefaultRecommendationDelete: 'DefaultRecommendationDelete' as const,
    DefaultRecommendationUpdate: 'DefaultRecommendationUpdate' as const,
    DeleteUser: 'DeleteUser' as const,
    ForgotPassword: 'ForgotPassword' as const,
    Login: 'Login' as const,
    OptOutUser: 'OptOutUser' as const,
    RecommendationCreate: 'RecommendationCreate' as const,
    RecommendationUpdate: 'RecommendationUpdate' as const,
    SuggestAdministrationPlace: 'SuggestAdministrationPlace' as const,
    UpdateEmailAsAdmin: 'UpdateEmailAsAdmin' as const,
    UpdatePasswordAsAdmin: 'UpdatePasswordAsAdmin' as const,
    UpdatePassword: 'UpdatePassword' as const,
    UserCreate: 'UserCreate' as const,
    UserUpdate: 'UserUpdate' as const,
  },
  Fragment: {
    AdministrationPlaceFields: 'AdministrationPlaceFields' as const,
    DoctorFields: 'DoctorFields' as const,
    DoctorRecommendationType: 'DoctorRecommendationType' as const,
    DoctorImunocardCount: 'DoctorImunocardCount' as const,
    FAQType: 'FAQType' as const,
    ListCategoriesRecommendationType: 'ListCategoriesRecommendationType' as const,
    VaccineFamilyFields: 'VaccineFamilyFields' as const,
    VaccineFields: 'VaccineFields' as const,
  },
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
};

/** Administration place kind enum */
export enum AdministrationPlaceKind {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

/** Administration place search filter */
export type AdministrationPlaceSearchFilterInput = {
  /** Whether administration places with home vaccination are included. If not defined, this filter is not included */
  includeHomeVaccination?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether private administration places are included */
  includePrivate: Scalars['Boolean']['input'];
  /** Whether public administration places are included */
  includePublic: Scalars['Boolean']['input'];
};

/** Vaccine administration place suggestion. */
export type AdministrationPlaceSuggestionInput = {
  /** Administration place id */
  administrationPlaceId?: InputMaybe<Scalars['ID']['input']>;
  /** Administration place city */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Some comment regarding this suggestion */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Administration place complement */
  complement?: InputMaybe<Scalars['String']['input']>;
  /** Administration place email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Whether this place administrates vaccination at home */
  homeVaccination?: InputMaybe<Scalars['Boolean']['input']>;
  /** Administration place name */
  name: Scalars['String']['input'];
  /** Administration place neighborhood */
  neighborhood?: InputMaybe<Scalars['String']['input']>;
  /** Administration place phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Administration state */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Administration place street */
  street?: InputMaybe<Scalars['String']['input']>;
  /** Administration place zip code */
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

/** Vaccine administration place suggestion. */
export type AdministrationPlaceSuggestionType = {
  __typename?: 'AdministrationPlaceSuggestionType';
  /** Administration place id */
  administrationPlaceId?: Maybe<Scalars['ID']['output']>;
  /** Administration place city */
  city?: Maybe<Scalars['String']['output']>;
  /** Some comment regarding this suggestion */
  comment?: Maybe<Scalars['String']['output']>;
  /** Administration place complement */
  complement?: Maybe<Scalars['String']['output']>;
  /** Administration place email */
  email?: Maybe<Scalars['String']['output']>;
  /** Whether this place administrates vaccination at home */
  homeVaccination?: Maybe<Scalars['Boolean']['output']>;
  /** Administration place suggestion id */
  id?: Maybe<Scalars['ID']['output']>;
  /** Administration place name */
  name: Scalars['String']['output'];
  /** Administration place neighborhood */
  neighborhood?: Maybe<Scalars['String']['output']>;
  /** Administration place phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Administration state */
  state?: Maybe<Scalars['String']['output']>;
  /** Administration place street */
  street?: Maybe<Scalars['String']['output']>;
  /** Administration place zip code */
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Vaccine administration place. */
export type AdministrationPlaceType = {
  __typename?: 'AdministrationPlaceType';
  /** Administration place city */
  city?: Maybe<Scalars['String']['output']>;
  /** Administration place complement */
  complement?: Maybe<Scalars['String']['output']>;
  /**
   * Administration place distance. This
   *   can be null if the server doesnt have a reference to consider when computing this distance
   */
  distance?: Maybe<Scalars['Float']['output']>;
  /** Administration place email */
  email?: Maybe<Scalars['String']['output']>;
  /** Whether home vaccination is offered */
  homeVaccination?: Maybe<Scalars['Boolean']['output']>;
  /** Administration place id */
  id: Scalars['ID']['output'];
  /** Administration place latitude */
  latitude: Scalars['Float']['output'];
  /** Administration place longitude */
  longitude: Scalars['Float']['output'];
  /** Administration place name */
  name: Scalars['String']['output'];
  /** Administration place neighborhood */
  neighborhood?: Maybe<Scalars['String']['output']>;
  /** Administration place phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Whether shingrix vaccine is offered */
  shingrix?: Maybe<Scalars['Boolean']['output']>;
  /** Administration state */
  state?: Maybe<Scalars['String']['output']>;
  /** Administration place street */
  street?: Maybe<Scalars['String']['output']>;
  /** Administration place kind */
  type: AdministrationPlaceKind;
  /** Administration place zip code */
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Administration places for position input */
export type AdministrationPlacesForPositionInput = {
  /** Administration place search filter */
  filter?: InputMaybe<AdministrationPlaceSearchFilterInput>;
  /** Whether it should be included at least one public administration place */
  includePublicAdministrationPlace?: InputMaybe<Scalars['Boolean']['input']>;
  /** Administration place latitude */
  latitude: Scalars['Float']['input'];
  /** Administration place longitude */
  longitude: Scalars['Float']['input'];
  /** The maximum distance allowed from search position */
  maxDistanceInKm?: InputMaybe<Scalars['Int']['input']>;
};

/** Category for default recommendations */
export type CategoryRecommendationType = {
  __typename?: 'CategoryRecommendationType';
  /** Recommendation category */
  category?: Maybe<Scalars['String']['output']>;
  /** Recommendation category id */
  id: Scalars['ID']['output'];
};

/** Change password input object. */
export type ChangePasswordInput = {
  /** New password */
  newPassword: Scalars['String']['input'];
  /** Old password */
  oldPassword: Scalars['String']['input'];
};

/** Contact screen input */
export type ContactInput = {
  /** sender email */
  email: Scalars['String']['input'];
  /** sender message */
  message: Scalars['String']['input'];
  /** sender name */
  name: Scalars['String']['input'];
};

/** Contact response */
export type ContactResponse = {
  __typename?: 'ContactResponse';
  /** Whether the email/message was sent */
  success: Scalars['Boolean']['output'];
};

/** A default recommendation delete input object. */
export type DefaultRecommendationDeleteInput = {
  /** Id of default recommendation to be deleted */
  id: Scalars['ID']['input'];
};

/** A default recommendation input object. */
export type DefaultRecommendationInput = {
  /** Selected category recommendation id */
  categoryRecommendationId: Scalars['String']['input'];
  /** Selected vaccines ids */
  vaccines: Array<InputMaybe<DefaultRecommendationVaccineInput>>;
};

/** A default recommendation */
export type DefaultRecommendationType = {
  __typename?: 'DefaultRecommendationType';
  /** Patient age */
  age?: Maybe<Scalars['String']['output']>;
  /** Recommendation category id */
  categoryRecommendationId: Scalars['ID']['output'];
  /** Doctor hash */
  doctorHash: Scalars['String']['output'];
  /** Default recommendation id */
  id: Scalars['ID']['output'];
  /** Associated vaccines */
  vaccines?: Maybe<Array<Maybe<VaccineType>>>;
};

/** A default recommendation update input object. */
export type DefaultRecommendationUpdateInput = {
  /** Id of default recommendation to be updated */
  id: Scalars['ID']['input'];
  /** Selected vaccines ids */
  vaccines: Array<InputMaybe<DefaultRecommendationVaccineInput>>;
};

/** A default recommendation vaccine input object. */
export type DefaultRecommendationVaccineInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  vaccineId?: InputMaybe<Scalars['String']['input']>;
};

/** Represents the count of immunocards associated with a doctor. */
export type DoctorImunocardCount = {
  __typename?: 'DoctorImunocardCount';
  /** The number of active immunocards associated with the doctor. */
  activeImunocardCount?: Maybe<Scalars['Float']['output']>;
  /** The total number of immunocards associated with the doctor. */
  imunocardCount?: Maybe<Scalars['Float']['output']>;
};

/** An user input object. */
export type DoctorInput = {
  /** Doctor crm */
  crm: Scalars['String']['input'];
  /** Doctor email */
  email: Scalars['String']['input'];
  /** Doctor name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether user wants to receive VacinaCerta email newsletter */
  newsletterOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  /** Doctor password */
  password: Scalars['String']['input'];
  /** Doctor phone */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Whether user wants to receive VacinaCerta SMS */
  smsOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  /** Doctor federal unit */
  uf: Scalars['String']['input'];
};

/** Doctor recommendation input object. */
export type DoctorRecommendationInput = {
  /** Field to order by (creationDate, name). */
  orderBy?: InputMaybe<OrderByEnum>;
  /** Order direction (ASC, DESC). */
  orderDirection?: InputMaybe<OrderDirectionEnum>;
  /** Page number */
  page?: InputMaybe<Scalars['Float']['input']>;
  /** Page size */
  pageSize?: InputMaybe<Scalars['Float']['input']>;
};

/** A doctor recommendation */
export type DoctorRecommendationResponse = {
  __typename?: 'DoctorRecommendationResponse';
  /** Recommendations */
  recommendations: Array<Maybe<DoctorRecommendationType>>;
  /** Total recommendations */
  totalPages: Scalars['Int']['output'];
  /** Total recommendations */
  totalRecommendations: Scalars['Int']['output'];
};

/** A doctor recommendation */
export type DoctorRecommendationType = {
  __typename?: 'DoctorRecommendationType';
  /** Recommendation creation date */
  createdAt: Scalars['Date']['output'];
  /** Recommendation id */
  id: Scalars['ID']['output'];
  /** Patient imunocard related to this recommendation */
  imunocard?: Maybe<Scalars['String']['output']>;
  /** Patient name related to this recommendation */
  patientName?: Maybe<Scalars['String']['output']>;
  /** Recommendation image url */
  recommendationUrl?: Maybe<Scalars['String']['output']>;
  /** Recommendation type (default or custom) */
  type?: Maybe<RecommendationKind>;
};

/** A doctor */
export type DoctorType = {
  __typename?: 'DoctorType';
  /** Doctor avatar url */
  avatarUrl: Scalars['String']['output'];
  /** Doctor CRM */
  crm: Scalars['String']['output'];
  /** Doctor email */
  email: Scalars['String']['output'];
  /** Doctor id */
  id: Scalars['ID']['output'];
  /** Doctor name */
  name: Scalars['String']['output'];
  /** Whether user wants to receive VacinaCerta email newsletter */
  newsletterOptIn?: Maybe<Scalars['Boolean']['output']>;
  /** Doctor phone */
  phone?: Maybe<Scalars['String']['output']>;
  /** Remember me login option */
  rememberMe?: Maybe<Scalars['Boolean']['output']>;
  /** Doctor rep territory code */
  repTerritory?: Maybe<Scalars['String']['output']>;
  /** Doctor rep adult territory code */
  repTerritoryAdult?: Maybe<Scalars['String']['output']>;
  /** Doctor role */
  role: Scalars['String']['output'];
  /** Whether user wants to receive VacinaCerta sms */
  smsOptIn?: Maybe<Scalars['Boolean']['output']>;
  /** Doctor federal unit */
  uf: Scalars['String']['output'];
};

/** FAQ type representing a frequently asked question */
export type FaqType = {
  __typename?: 'FAQType';
  /** The FAQ answer in HTML format */
  answer?: Maybe<Scalars['String']['output']>;
  /** The category of the FAQ (Doctor or Public) */
  category?: Maybe<FaqCategoryType>;
  /** Unique identifier for the FAQ */
  id?: Maybe<Scalars['ID']['output']>;
  /** The FAQ question */
  question?: Maybe<Scalars['String']['output']>;
  /** Bibliographic reference for the FAQ */
  reference?: Maybe<Scalars['String']['output']>;
};

export enum FaqCategoryType {
  DOCTOR = 'DOCTOR',
  PUBLIC = 'PUBLIC',
}

/** Forgot password input object. */
export type ForgotPasswordInput = {
  /** Doctor CRM (brazillian doctor id */
  crm?: InputMaybe<Scalars['String']['input']>;
  /** Doctor email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Template theme */
  theme?: InputMaybe<Scalars['String']['input']>;
  /** Doctor UF (Brazil state initials */
  uf?: InputMaybe<Scalars['String']['input']>;
};

/** Forgot password response type. */
export type ForgotPasswordType = {
  __typename?: 'ForgotPasswordType';
  /** Forgot password flow final message. */
  message: Scalars['String']['output'];
};

/** Input object for fetching FAQs */
export type GetFaqInput = {
  /** FAQ category (DOCTOR or PUBLIC) */
  category?: InputMaybe<FaqCategoryType>;
  /** Page number */
  page: Scalars['Int']['input'];
  /** Number of items per page */
  pageSize: Scalars['Int']['input'];
};

export type GetFaqResponseType = {
  __typename?: 'GetFaqResponseType';
  /** List of FAQs */
  faq?: Maybe<Array<Maybe<FaqType>>>;
  /** Total number of FAQs */
  totalFaq?: Maybe<Scalars['Int']['output']>;
  /** Total number of pages */
  totalPages?: Maybe<Scalars['Int']['output']>;
};

/** List of categories for default recommendations */
export type ListCategoriesRecommendationType = {
  __typename?: 'ListCategoriesRecommendationType';
  /** Recommendation categories */
  categories?: Maybe<Array<Maybe<CategoryRecommendationType>>>;
};

/** Login input object. */
export type LoginInput = {
  /** Doctor email */
  email: Scalars['String']['input'];
  /** Doctor password */
  password: Scalars['String']['input'];
  /** If server should remember user */
  rememberMe: Scalars['Boolean']['input'];
};

/** Perform actions over the backend */
export type Mutations = {
  __typename?: 'Mutations';
  AllUserDataRequest?: Maybe<DoctorType>;
  ChangePassword?: Maybe<DoctorType>;
  Contact?: Maybe<ContactResponse>;
  DefaultRecommendationCreate?: Maybe<DefaultRecommendationType>;
  DefaultRecommendationDelete?: Maybe<DefaultRecommendationType>;
  DefaultRecommendationUpdate?: Maybe<DefaultRecommendationType>;
  DeleteUser?: Maybe<DoctorType>;
  ForgotPassword?: Maybe<ForgotPasswordType>;
  Login?: Maybe<UserAuthenticationType>;
  OptOutUser?: Maybe<OptOutUserType>;
  RecommendationCreate?: Maybe<RecommendationType>;
  RecommendationDelete?: Maybe<RecommendationType>;
  RecommendationUpdate?: Maybe<RecommendationType>;
  SuggestAdministrationPlace?: Maybe<AdministrationPlaceSuggestionType>;
  UpdateEmailAsAdmin?: Maybe<UpdateEmailAsAdminType>;
  UpdatePassword?: Maybe<UpdatePasswordType>;
  UpdatePasswordAsAdmin?: Maybe<UpdatePasswordAsAdminType>;
  UserCreate?: Maybe<UserAuthenticationType>;
  UserUpdate?: Maybe<DoctorType>;
};

/** Perform actions over the backend */
export type MutationsChangePasswordArgs = {
  data: ChangePasswordInput;
};

/** Perform actions over the backend */
export type MutationsContactArgs = {
  data: ContactInput;
};

/** Perform actions over the backend */
export type MutationsDefaultRecommendationCreateArgs = {
  data?: InputMaybe<DefaultRecommendationInput>;
};

/** Perform actions over the backend */
export type MutationsDefaultRecommendationDeleteArgs = {
  data?: InputMaybe<DefaultRecommendationDeleteInput>;
};

/** Perform actions over the backend */
export type MutationsDefaultRecommendationUpdateArgs = {
  data?: InputMaybe<DefaultRecommendationUpdateInput>;
};

/** Perform actions over the backend */
export type MutationsForgotPasswordArgs = {
  data: ForgotPasswordInput;
};

/** Perform actions over the backend */
export type MutationsLoginArgs = {
  data: LoginInput;
};

/** Perform actions over the backend */
export type MutationsOptOutUserArgs = {
  data: OptOutUserInput;
};

/** Perform actions over the backend */
export type MutationsRecommendationCreateArgs = {
  data?: InputMaybe<RecommendationInput>;
};

/** Perform actions over the backend */
export type MutationsRecommendationDeleteArgs = {
  data?: InputMaybe<RecommendationDeleteInput>;
};

/** Perform actions over the backend */
export type MutationsRecommendationUpdateArgs = {
  data?: InputMaybe<RecommendationUpdateInput>;
};

/** Perform actions over the backend */
export type MutationsSuggestAdministrationPlaceArgs = {
  data: AdministrationPlaceSuggestionInput;
};

/** Perform actions over the backend */
export type MutationsUpdateEmailAsAdminArgs = {
  data: UpdateEmailAsAdminInput;
};

/** Perform actions over the backend */
export type MutationsUpdatePasswordArgs = {
  data: UpdatePasswordInput;
};

/** Perform actions over the backend */
export type MutationsUpdatePasswordAsAdminArgs = {
  data: UpdatePasswordAsAdminInput;
};

/** Perform actions over the backend */
export type MutationsUserCreateArgs = {
  data?: InputMaybe<DoctorInput>;
};

/** Perform actions over the backend */
export type MutationsUserUpdateArgs = {
  data?: InputMaybe<UpdateDoctorInput>;
};

/** Opt out user input object. */
export type OptOutUserInput = {
  /** Doctor CRM (brazillian doctor id */
  crm?: InputMaybe<Scalars['String']['input']>;
  /** Doctor email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Doctor UF (Brazil state initials */
  uf?: InputMaybe<Scalars['String']['input']>;
};

/** Opt out user response type. */
export type OptOutUserType = {
  __typename?: 'OptOutUserType';
  /** Opt out user flow final message. */
  message: Scalars['String']['output'];
  /** Whether opt out was succesful */
  success: Scalars['Boolean']['output'];
};

export enum OrderByEnum {
  CREATION_DATE = 'CREATION_DATE',
  NAME = 'NAME',
}

export enum OrderDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

/** A patient recommendation input object. */
export type PatientRecommendationInput = {
  /** Patient imunocard */
  imunocard: Scalars['String']['input'];
};

/** Reads from the backend */
export type Queries = {
  __typename?: 'Queries';
  AdministrationPlacesForPosition?: Maybe<Array<Maybe<AdministrationPlaceType>>>;
  AllVaccineFamiliesByAge?: Maybe<Array<Maybe<VaccineFamilyByAgeResponse>>>;
  DefaultRecommendations?: Maybe<Array<Maybe<DefaultRecommendationType>>>;
  Doctor?: Maybe<DoctorType>;
  DoctorRecommendations?: Maybe<DoctorRecommendationResponse>;
  GetDoctorImunocardCount?: Maybe<DoctorImunocardCount>;
  GetFaqs?: Maybe<GetFaqResponseType>;
  ListCategoriesRecommendation?: Maybe<ListCategoriesRecommendationType>;
  PatientRecommendations?: Maybe<Array<Maybe<RecommendationType>>>;
  Recommendations?: Maybe<Array<Maybe<RecommendationType>>>;
  VaccineByFamily?: Maybe<Array<Maybe<VaccineByFamilyResponse>>>;
  VaccineBySpecialNeed?: Maybe<Array<Maybe<VaccineBySpecialNeedResponse>>>;
};

/** Reads from the backend */
export type QueriesAdministrationPlacesForPositionArgs = {
  data: AdministrationPlacesForPositionInput;
};

/** Reads from the backend */
export type QueriesDoctorArgs = {
  id?: InputMaybe<Scalars['Float']['input']>;
};

/** Reads from the backend */
export type QueriesDoctorRecommendationsArgs = {
  data?: InputMaybe<DoctorRecommendationInput>;
};

/** Reads from the backend */
export type QueriesGetFaqsArgs = {
  data?: InputMaybe<GetFaqInput>;
};

/** Reads from the backend */
export type QueriesPatientRecommendationsArgs = {
  data?: InputMaybe<PatientRecommendationInput>;
};

/** Reads from the backend */
export type QueriesVaccineByFamilyArgs = {
  data?: InputMaybe<VaccineByFamilyInput>;
};

/** Reads from the backend */
export type QueriesVaccineBySpecialNeedArgs = {
  data?: InputMaybe<VaccineBySpecialNeedInput>;
};

/** A recommendation administration place input object. */
export type RecommendationAdministrationPlaceInput = {
  administrationPlaceId?: InputMaybe<Scalars['String']['input']>;
};

/** A recommendation delete input object. */
export type RecommendationDeleteInput = {
  /** Id of recommendation to be deleted */
  id: Scalars['ID']['input'];
};

/** A recommendation input object. */
export type RecommendationInput = {
  /** Selected administration places ids */
  administrationPlacesIds?: InputMaybe<Array<InputMaybe<RecommendationAdministrationPlaceInput>>>;
  /** Age selected on the recommendation flow */
  age?: InputMaybe<Scalars['String']['input']>;
  /** Doctor crm */
  crm?: InputMaybe<Scalars['String']['input']>;
  /** Imunocard number */
  imunocard?: InputMaybe<Scalars['String']['input']>;
  /** Associated rep territory identifier */
  repTerritory?: InputMaybe<Scalars['String']['input']>;
  /** Doctor federal unit */
  uf?: InputMaybe<Scalars['String']['input']>;
  /** Selected vaccines */
  vaccines?: InputMaybe<Array<InputMaybe<RecommendationVaccineInput>>>;
};

/** Recommendation kind enum */
export enum RecommendationKind {
  CUSTOM = 'CUSTOM',
  DEFAULT = 'DEFAULT',
}

/** A recommendation */
export type RecommendationType = {
  __typename?: 'RecommendationType';
  /** Associated administration places */
  administrationPlaces: Array<Maybe<AdministrationPlaceType>>;
  /** Patient age */
  age?: Maybe<Scalars['String']['output']>;
  /** Doctor hash */
  doctorHash: Scalars['String']['output'];
  /** Whether the recommendation has been sent through email or not */
  email?: Maybe<Scalars['Boolean']['output']>;
  /** Recommendation id */
  id: Scalars['ID']['output'];
  /** Whether the recommendation has been printed or not */
  print?: Maybe<Scalars['Boolean']['output']>;
  /** Recommendation image url */
  recommendationUrl?: Maybe<Scalars['String']['output']>;
  /** Associated vaccines */
  vaccines?: Maybe<Array<Maybe<VaccineType>>>;
};

/** A recommendation update input object. */
export type RecommendationUpdateInput = {
  /** Whether recommendation has been sent through email or not */
  email?: InputMaybe<Scalars['Boolean']['input']>;
  /** Id of recommendation to be updated */
  id: Scalars['ID']['input'];
  /** Whether recommendation has been printed or not */
  print?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether vaccine brands are visible or not */
  vaccineBrand?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A recommendation vaccine input object. */
export type RecommendationVaccineInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  vaccineId?: InputMaybe<Scalars['String']['input']>;
};

/** An user input object. */
export type UpdateDoctorInput = {
  /** Doctor email */
  email: Scalars['String']['input'];
  /** Whether user wants to receive VacinaCerta email newsletter */
  newsletterOptIn?: InputMaybe<Scalars['Boolean']['input']>;
  /** Doctor phone */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Whether user wants to receive VacinaCerta sms */
  smsOptIn?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Update password input object. */
export type UpdateEmailAsAdminInput = {
  /** Doctor crm */
  crm: Scalars['String']['input'];
  /** Doctor email */
  email: Scalars['String']['input'];
  /** Doctor federal unit */
  uf: Scalars['String']['input'];
};

/** Update email as admin response type. */
export type UpdateEmailAsAdminType = {
  __typename?: 'UpdateEmailAsAdminType';
  /** Response message */
  message: Scalars['String']['output'];
};

/** Update password input object. */
export type UpdatePasswordAsAdminInput = {
  /** Doctor crm */
  crm: Scalars['String']['input'];
  /** Doctor federal unit */
  uf: Scalars['String']['input'];
};

/** Update password as admin response type. */
export type UpdatePasswordAsAdminType = {
  __typename?: 'UpdatePasswordAsAdminType';
  /** Doctor email */
  email: Scalars['String']['output'];
  /** Doctor name */
  name: Scalars['String']['output'];
  /** Doctor password */
  password: Scalars['String']['output'];
};

/** Update password input object. */
export type UpdatePasswordInput = {
  /** Doctor forgot password code */
  code: Scalars['String']['input'];
  /** Doctor email */
  email: Scalars['String']['input'];
  /** Doctor new password */
  password: Scalars['String']['input'];
};

/** Update password response type. */
export type UpdatePasswordType = {
  __typename?: 'UpdatePasswordType';
  /** Update password flow final message. */
  message: Scalars['String']['output'];
};

/** Login type */
export type UserAuthenticationType = {
  __typename?: 'UserAuthenticationType';
  /** JWT token */
  token: Scalars['String']['output'];
  user: DoctorType;
};

/** Vaccine by family input */
export type VaccineByFamilyInput = {
  /** Array of id */
  families: Array<InputMaybe<Scalars['ID']['input']>>;
};

/** Vaccine grouped by family response. */
export type VaccineByFamilyResponse = {
  __typename?: 'VaccineByFamilyResponse';
  /** Vaccine family. */
  family: VaccineFamilyType;
  /** Vaccines for a family. */
  vaccines: Array<Maybe<VaccineType>>;
};

/** Vaccine by special need input */
export type VaccineBySpecialNeedInput = {
  /** special need (ex: DIABETES) */
  specialNeed: Scalars['String']['input'];
};

/** Vaccine grouped by family response. */
export type VaccineBySpecialNeedResponse = {
  __typename?: 'VaccineBySpecialNeedResponse';
  /** Vaccine family. */
  family: VaccineFamilyType;
  /** Vaccines for a family. */
  vaccines: Array<Maybe<VaccineType>>;
};

/** Vaccine family grouped by age response. */
export type VaccineFamilyByAgeResponse = {
  __typename?: 'VaccineFamilyByAgeResponse';
  /** Families for an age. */
  families: Array<Maybe<VaccineFamilyType>>;
  /** age (in months) */
  months: Scalars['Int']['output'];
};

/** Vaccine family grouped by age response. */
export type VaccineFamilyType = {
  __typename?: 'VaccineFamilyType';
  /** Vaccine family id */
  id: Scalars['ID']['output'];
  /** Vaccine family name */
  name: Scalars['String']['output'];
};

/** Vaccine family grouped by age response. */
export type VaccineType = {
  __typename?: 'VaccineType';
  /** Ages (in months) to be administered */
  ages?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** Vaccine brand */
  brand?: Maybe<Scalars['String']['output']>;
  /** Vaccine comments/observations */
  comment?: Maybe<Scalars['String']['output']>;
  /** Vaccine description */
  description?: Maybe<Scalars['String']['output']>;
  /** Vaccine id */
  id: Scalars['ID']['output'];
  /** Number of injections for this vaccine */
  injectionCount: Scalars['Int']['output'];
  /** Whether this vaccine is administered on private clinics */
  isAdministeredOnPrivateClinics: Scalars['Boolean']['output'];
  /** Whether this vaccine is administered on public clinics */
  isAdministeredOnPublicClinics: Scalars['Boolean']['output'];
  /** Indicates whether the vaccine is live attenuated */
  isLiveAttenuated: Scalars['Boolean']['output'];
  /** Vaccine laboratory */
  laboratory?: Maybe<Scalars['String']['output']>;
  /** Number of mouth drops for this vaccine */
  mouthDropCount: Scalars['Int']['output'];
  /** Vaccine name */
  name: Scalars['String']['output'];
  /** Vaccine ordenation number made by doctor when recommended the vaccine */
  order?: Maybe<Scalars['Int']['output']>;
  /** Vaccine priority (related to "specialNeed" field - Whether the vaccine falls into the "especialmente recomendadas" (2) or "outras vacinas recomendadas (1) group */
  priority?: Maybe<Scalars['Int']['output']>;
  /** Comment made by doctor when recommended the vaccine */
  recommendationComment?: Maybe<Scalars['String']['output']>;
  /** Special need name (a.k.a immunocompromised comorbidities) */
  specialNeed?: Maybe<Scalars['String']['output']>;
  /** Vaccine family Id */
  vaccineFamilyId?: Maybe<Scalars['Int']['output']>;
};
